"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InfiniteScroll = void 0;
const react_1 = __importStar(require("react"));
const InfiniteScroll = (props) => {
    const [numberOfVisible, setNumberOfVisible] = (0, react_1.useState)(props.elements);
    const checkBottom = () => {
        const d = document.documentElement;
        const offset = d.scrollTop + window.innerHeight;
        const height = d.offsetHeight;
        if (offset >= height - 100) {
            console.log(numberOfVisible + props.elements);
            setNumberOfVisible(numberOfVisible + props.elements);
        }
    };
    (0, react_1.useEffect)(() => {
        window.addEventListener("scroll", checkBottom);
        return () => {
            window.removeEventListener("scroll", checkBottom);
        };
    });
    return (react_1.default.createElement(react_1.default.Fragment, null, props.scrollContent
        .filter((el, index) => index < numberOfVisible)
        .map((el) => {
        return props.children(el);
    })));
};
exports.InfiniteScroll = InfiniteScroll;
